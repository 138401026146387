// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout';
import projetosDeLei from '@src/views/apps/projetos-de-lei/store/reducer';
import BillingReducer from './BillingReducer';
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  projetosDeLei,
  billing: BillingReducer, 
});

export default rootReducer
